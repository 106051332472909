<template>
  <v-navigation-drawer v-model="drawer" :mini-variant="mini" app dark permanent>
    <v-list>
      <v-list-item>
        <v-list-item-icon>
          <v-icon>mdi-file-document-multiple</v-icon>
        </v-list-item-icon>
        <v-list-item-title class="white--text">メニュー</v-list-item-title>
      </v-list-item>
    </v-list>
    <v-divider/>
    <v-list nav dense>
      <v-list-item-group color="primary">
        <GlobalSideBarLink :path="'/dashboard'" :title="'ダッシュボード'" :icon="'mdi-view-dashboard-variant'"/>
        <v-divider class="mx-4"></v-divider>
        <v-subheader v-if="!mini">案件管理</v-subheader>
        <GlobalSideBarLink :path="'/projectmaster'" :title="'案件情報'" :icon="'mdi-hexagon-multiple'" :isDisabled="!isDisabled('S02GPRO','isBrowse')"/>
        <v-divider class="mx-4"></v-divider>
        <v-subheader v-if="!mini">マスタ管理</v-subheader>
        <GlobalSideBarLink :path="'/permissionmaster'" :title="'権限情報'" :icon="'mdi-shield-key'" :isDisabled="!isDisabled('S02GAUT','isBrowse')"/>
        <GlobalSideBarLink :path="'/companymaster'" :title="'企業情報'" :icon="'mdi-city-variant'" :isDisabled="!isDisabled('S02GCOM','isBrowse')"/>
        <!-- :isDisabled="!isDisabled('S02GCOM','isBrowse')" -->
        <GlobalSideBarLink :path="'/departmentmaster'" :title="'部署情報'" :icon="'mdi-office-building-cog'" :isDisabled="!isDisabled('S02GDEP','isBrowse')"/>
        <GlobalSideBarLink :path="'/usermaster'" :title="'ユーザー情報'" :icon="'mdi-account'" :isDisabled="!isDisabled('S02GUSE','isBrowse')"/>
        <GlobalSideBarLink :path="'/suppliersmaster'" :title="'取引先情報'" :icon="'mdi-card-account-details'" :isDisabled="!isDisabled('S02GSUP','isBrowse')"/>
        <GlobalSideBarLink :path="'/categorymaster'" :title="'カテゴリ情報'" :icon="'mdi-format-list-text'" :isDisabled="!isDisabled('S02GCAT','isBrowse')"/>
        <GlobalSideBarLink :path="'/itemmaster'" :title="'アイテム情報'" :icon="'mdi-cube-scan'" :isDisabled="!isDisabled('S02GITE','isBrowse')"/>
        <!-- <GlobalSideBarLink :path="'/structuremaster'" :title="'構成名称情報'" :icon="'mdi-relation-one-to-many'" :isDisabled="!isDisabled('S02GCNI','isBrowse')"/> -->
        <!-- <GlobalSideBarLink :path="'/configurationinformation'" :title="'構成情報'" :icon="'mdi-file-tree'" :isDisabled="!isDisabled('S02GCIN','isBrowse')"/> -->
        <GlobalSideBarLink :path="'/costitemmaster'" :title="'原価費目情報'" :icon="'mdi-database-edit'" :isDisabled="!isDisabled('S02GCOI','isBrowse')"/>
      </v-list-item-group>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import utilCommon from "@/helpers/utilCommon"
import GlobalSideBarLink from '@/views/layout/modules/GlobalSideBarLink';
export default {
  name: "GlobalSideBar",
  components: {
    GlobalSideBarLink
  },
  computed: {
    drawer: {
      get () {
        return this.$store.state.sidebar.drawer
      },
      set (value) {
        this.$store.commit('setDrawer', value)
      }
    },
    mini: {
      get () {
        return this.$store.state.sidebar.mini
      },
      set (value) {
        this.$store.commit('setMini', value)
      }
    },
  },
  methods :{
    isDisabled(display, control){
      return utilCommon.getDisabled(this.$store, display, control);
    }
  }
}
</script>

<style scoped>
</style>
