import Vue from "vue"
import Router from "vue-router"
import MainLayout from "@/views/layout/MainLayout"

import TestPage from "@/views/testPage/router/router";

import NotFound from "@/views/notFound/router/router";
// import LandingView from "@/views/landing/router/router";
import LoginView from "@/views/login/router/router";
import DashboardView from "@/views/dashboard/router/router";
import CostItemMaster from "@/views/costItemMaster/router/router";
import DepartmentMaster from "@/views/departmentMaster/router/router";
import ItemMaster from "@/views/itemMaster/router/router";
import CategoryMaster from "@/views/categoryMaster/router/router";
import CategoryMasterDetail from "@/views/categoryMasterDetail/router/router";
import CategoryMasterDetailItem from "@/views/categoryMasterDetailItem/router/router";
import SuppliersMaster from "@/views/suppliersMaster/router/router";
import ProjectMaster from "@/views/projectMaster/router/router";
import PermissionMaster from "@/views/permissionMaster/router/router";
import ProjectMasterDetail from "@/views/projectMasterDetail/router/router";
import UserMaster from "@/views/userMaster/router/router";
import CompanyMaster from "@/views/companyMaster/router/router";
import CreateQuotation from '@/views/createQuotation/router/router';
import CreateCostList from '@/views/createCostList/router/router';
import ConfigurationInformation from '@/views/configurationInformation/router/router';
import StructureMaster from '@/views/structureMaster/router/router';

Vue.use(Router)

export default new Router({
    // デフォルトの挙動では URL に `#` が含まれる.
    // URL から hash を取り除くには `mode:history` を指定する
    mode: "history",
    scrollBehavior() {
        return { x: 0, y: 0 }
    },
    base: process.env.BASE_URL,
    routes: [
        NotFound,
        // LandingView,
        LoginView,
        {
            name: "Home",
            path: "/home",
            layout: "MainLayout",
            component: MainLayout,
            children: [
                TestPage,
                DashboardView,
                CostItemMaster,
                DepartmentMaster,
                ItemMaster,
                CategoryMaster,
                CategoryMasterDetail,
                CategoryMasterDetailItem,
                SuppliersMaster,
                ProjectMaster,
                PermissionMaster,
                ProjectMasterDetail,
                UserMaster,
                CompanyMaster,
                CreateQuotation,
                CreateCostList,
                ConfigurationInformation,
                StructureMaster
            ]
        }
    ]
})
