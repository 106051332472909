import axios from 'axios';
import saveAs from 'file-saver';


function authHeaders(token, config = { headers: {} }) {
    // config.headers['Authorization'] = `Bearer ${token}`;
    return config;
}

function getFileName(contentDisposition) {
    let fileName;
    if(contentDisposition.indexOf("utf-8") > 0) {
        fileName = contentDisposition.substring(contentDisposition.indexOf("utf-8") + 7, contentDisposition.length);
    } else {
        fileName = contentDisposition.substring(contentDisposition.indexOf("filename") + 9, contentDisposition.length).replace(/"/g, "");
    }
    //デコードするとスペースが"+"になるのでスペースへ置換します
    fileName = decodeURI(fileName).replace(/\+/g, " ");

    return fileName;
}

const instance = axios.create({
    baseURL: "/api",
});

export const api = {
    async logInMsal(data) {
        return instance.post("/login/msal", data);
    },
    /**
     * ログイン
     * @param {*} username
     * @param {*} password
     * @returns response
     */
    async logInGetToken(username, password) {
        const params = new URLSearchParams();
        params.append('username', username);
        params.append('password', password);
        return instance.post("/login/access-token", params);
    },
    async getMe(token) {
        return instance.get("/users/me", authHeaders(token));
    },
    async updateMe(token, data) {
        return instance.put("/users/me", data, authHeaders(token));
    },
    /**
     * ユーザ情報のリスト取得
     * @param {*} token
     * @returns response
     */
    async getUsers(token) {
        return instance.get("/users/", authHeaders(token));
    },
    /**
     * ユーザ情報の登録
     * @param {*} token
     * @param {*} data
     * @returns response
     */
    async createUser(token, data) {
        return instance.post("/users/", data, authHeaders(token));
    },
    /**
     * ユーザ情報の更新
     * @param {*} token
     * @param {*} id
     * @param {*} data
     * @returns response
     */
    async updateUser(token, userId, data) {
        return instance.put(`/users/${userId}`, data, authHeaders(token));
    },
    async passwordRecovery(email) {
        return instance.post(`/password-recovery/${email}`);
    },
    async resetPassword(password, token) {
        return instance.post("/reset-password/", {
            new_password: password,
            token,
        });
    },
    /**
     * カテゴリ情報のリスト取得
     * @param {*} token
     * @returns response
     */
    async getCategories(token) {
        return instance.get("/categories/", authHeaders(token));
    },
    /**
     * カテゴリ情報の取得
     * @param {*} token
     * @param {*} id
     * @returns response
     */
    async getCategory(token, id) {
        return instance.get(`/categories/${id}`, authHeaders(token));
    },
    /**
     * カテゴリ情報の登録
     * @param {*} token
     * @param {*} data
     * @returns response
     */
    async createCategory(token, data) {
        return instance.post("/categories/", data, authHeaders(token));
    },
    /**
     * カテゴリ情報の更新
     * @param {*} token
     * @param {*} id
     * @param {*} data
     * @returns response
     */
    async updateCategory(token, id, data) {
        return instance.put(`/categories/${id}`, data, authHeaders(token));
    },
    /**
     * エクセル作成（テンプレートマスタ）
     * @param {*} token
     * @param {*} id
     * @returns response
     */
    async downloadExcelCategory(token, id) {
        let config = authHeaders(token, {
            responseType: 'blob',
            headers: {}
        });
        return instance.get(`/downloads/categories/excel/${id}`, config).then(response => {
            const blob = new Blob([response.data], {
                type: response.data.type
            });

            //レスポンスヘッダからファイル名を取得します
            const contentDisposition = response.headers["content-disposition"];
            const fileName = getFileName(contentDisposition)

            //ダウンロードします
            saveAs(blob, fileName);
        });
    },
    /**
     * エクセルファイルアップロード（テンプレートマスタ）
     * @param {*} token
     * @param {*} data
     * @returns response
     */
    async uploadExcelCategory(token, data) {
        return instance.post("/uploads/uploadtemplatefile/", data, authHeaders(token));
    },

    // /**
    //  * カテゴリ関連情報の登録
    //  * @param {*} token
    //  * @param {*} data
    //  * @returns response
    //  */
    // async createCategoryRelated(token, data) {
    //     return instance.post("/category_related/", data, authHeaders(token));
    // },

    /**
     * 原価費目情報のリスト取得
     * @param {*} token
     * @returns response
     */
    async getCostItems(token) {
        return instance.get("/costitems/", authHeaders(token));
    },
    /**
     * 原価費目情報の登録
     * @param {*} token
     * @param {*} data
     * @returns response
     */
    async createCostItem(token, data) {
        return instance.post("/costitems/", data, authHeaders(token));
    },
    /**
     * 原価費目情報の更新
     * @param {*} token
     * @param {*} id
     * @param {*} data
     * @returns response
     */
    async updateCostItem(token, id, data) {
        return instance.put(`/costitems/${id}`, data, authHeaders(token));
    },
    /**
     * 部署情報のリスト取得
     * @param {*} token
     * @returns response
     */
    async getDepartments(token) {
        return instance.get("/departments/", authHeaders(token));
    },
    /**
     * 部署情報の登録
     * @param {*} token
     * @param {*} data
     * @returns response
     */
    async createDepartment(token, data) {
        return instance.post("/departments/", data, authHeaders(token));
    },
    /**
     * 部署情報の更新
     * @param {*} token
     * @param {*} id
     * @param {*} data
     * @returns response
     */
    async updateDepartment(token, id, data) {
        return instance.put(`/departments/${id}`, data, authHeaders(token));
    },
    /**
     * アイテム情報のリスト取得
     * @param {*} token
     * @returns response
     */
    async getItems(token) {
        return instance.get("/items/", authHeaders(token));
    },
    /**
     * アイテム情報の登録
     * @param {*} token
     * @param {*} data
     * @returns response
     */
    async createItem(token, data) {
        return instance.post("/items/", data, authHeaders(token));
    },
    /**
     * アイテム情報の更新
     * @param {*} token
     * @param {*} id
     * @param {*} data
     * @returns response
     */
    async updateItem(token, id, data) {
        return instance.put(`/items/${id}`, data, authHeaders(token));
    },
    /**
     * アイテム名の重複チェック
     * @param {*} token
     * @returns response
     */
    async checkItemName(token, data) {
        return instance.post(`/items/duplication`, data, authHeaders(token));
    },
    /**
     * エクセル作成（アイテムマスタ）
     * @param {*} token
     * @param {*} id
     * @returns response
     */
    async downloadExcelItem(token, id) {
        let config = authHeaders(token, {
            responseType: 'blob',
            headers: {}
        });
        return instance.get(`/downloads/items/excel/${id}`, config).then(response => {
            const blob = new Blob([response.data], {
                type: response.data.type
            });

            //レスポンスヘッダからファイル名を取得します
            const contentDisposition = response.headers["content-disposition"];
            const fileName = getFileName(contentDisposition)

            //ダウンロードします
            saveAs(blob, fileName);
        });
    },
    /**
     * エクセルファイルアップロード（アイテムマスタ）
     * @param {*} token
     * @param {*} data
     * @returns response
     */
    async uploadExcelItem(token, data) {
        return instance.post("/uploads/uploaditemsfile/", data, authHeaders(token));
    },

    /**
     * 取引先情報のリスト取得
     * @param {*} token
     * @returns response
     */
    async getSuppliers(token) {
        return instance.get("/suppliers/", authHeaders(token));
    },
    /**
     * 取引先情報の登録
     * @param {*} token
     * @param {*} data
     * @returns response
     */
    async createSupplier(token, data) {
        return instance.post("/suppliers/", data, authHeaders(token));
    },
    /**
     * 取引先情報の更新
     * @param {*} token
     * @param {*} id
     * @param {*} data
     * @returns response
     */
    async updateSupplier(token, id, data) {
        return instance.put(`/suppliers/${id}`, data, authHeaders(token));
    },
    /**
     * エクセル作成（取引先マスタ）
     * @param {*} token
     * @param {*} id
     * @returns response
     */
    async downloadExcelSupplier(token) {
        let config = authHeaders(token, {
            responseType: 'blob',
            headers: {}
        });
        return instance.get("/downloads/suppliers/excel/", config).then(response => {
            const blob = new Blob([response.data], {
                type: response.data.type
            });

            //レスポンスヘッダからファイル名を取得します
            const contentDisposition = response.headers["content-disposition"];
            const fileName = getFileName(contentDisposition)

            //ダウンロードします
            saveAs(blob, fileName);
        });
    },
    /**
     * エクセルファイルアップロード（取引先マスタ）
     * @param {*} token
     * @param {*} data
     * @returns response
     */
    async uploadExcelSupplier(token, data) {
        return instance.post("/uploads/uploadsuppliersfile/", data, authHeaders(token));
    },

    /**
     * 権限情報のリスト取得
     * @param {*} token
     * @returns response
     */
    async getPermissions(token) {
        return instance.get("/permissions/", authHeaders(token));
    },
    /**
     * 権限情報の登録
     * @param {*} token
     * @param {*} data
     * @returns response
     */
    async createPermission(token, data) {
        return instance.post("/permissions/", data, authHeaders(token));
    },
    /**
     * 権限情報の更新
     * @param {*} token
     * @param {*} id
     * @param {*} data
     * @returns response
     */
    async updatePermission(token, id, data) {
        return instance.put(`/permissions/${id}`, data, authHeaders(token));
    },
    /**
     * 案件情報のリスト取得
     * @param {*} token
     * @returns response
     */
    async getProjects(token) {
        return instance.get("/projects/", authHeaders(token));
    },
    /**
     * 案件情報の取得
     * @param {*} token
     * @param {*} project_id
     * @returns response
     */
    async getProject(token, project_id) {
        return instance.get(`/projects/${project_id}`, authHeaders(token));
    },
    /**
     * 案件情報の登録
     * @param {*} token
     * @param {*} data
     * @returns response
     */
    async createProject(token, data) {
        return instance.post("/projects/", data, authHeaders(token));
    },
    /**
     * 案件情報の更新
     * @param {*} token
     * @param {*} id
     * @param {*} data
     * @returns response
     */
    async updateProject(token, id, data) {
        return instance.put(`/projects/${id}`, data, authHeaders(token));
    },
    /**
     * 見積書情報のリスト取得
     * @param {*} token
     * @returns response
     */
    async getQuotations(token) {
        return instance.get("/quotations/", authHeaders(token));
    },
    /**
     * 特定ユーザーの見積書情報のリスト取得
     * @param {*} token
     * @param {*} user_id
     * @returns response
     */
    async getUserQuotations(token, user_id) {
        return instance.get(`/quotations/user/${user_id}`, authHeaders(token));
    },

    /**
     * 見積書情報の取得
     * @param {*} token
     * @param {*} project_id
     * @returns response
     */
    async getQuotation(token, quotation_id) {
        return instance.get(`/quotations/${quotation_id}`, authHeaders(token));
    },
    /**
     * 見積書情報の登録
     * @param {*} token
     * @param {*} data
     * @returns response
     */
    async createQuotation(token, data) {
        return instance.post("/quotations/", data, authHeaders(token));
    },

    /**
     * 見積書情報のコピー
     * @param {*} token
     * @param {*} data
     * @returns response
     */
    async copyQuotation(token, data) {
        return instance.put(`/quotations/copy`, data, authHeaders(token));
    },
    /**
     * 見積書情報の更新
     * @param {*} token
     * @param {*} id
     * @param {*} data
     * @returns response
     */
    async updateQuotation(token, id, data) {
        return instance.put(`/quotations/${id}`, data, authHeaders(token));
    },
    /**
     * 見積書情報の更新
     * @param {*} token
     * @param {*} id
     * @param {*} data
     * @returns response
     */
    async editQuotation(token, id, data, files) {
        const form = new FormData();
        // let value;
        form.append('quotation_in', JSON.stringify(data));
        //複数送りたいときは配列にしてあげる
        files.forEach(file => {
            form.append('files', file.file, file.name || file.file.name || file.file.filename)
        });
        let config = authHeaders(token, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
        return instance.post(`/quotations/edit/${id}`, form, config);
    },
    /**
     * 表示制御情報の取得
     * @param {*} token
     * @param {*} field_name
     */
    async getChildDivision(token, field_name) {
        return instance.get(`/display_controls/${field_name}`, authHeaders(token));
    },
    /**
     * 消費税情報の取得
     * @param {*} token
     * @returns response
     */
    async getTax(token) {
        return instance.get(`/taxes/`, authHeaders(token));
    },
    /**
     * エクセル作成(原価表作成画面)
     * @param {*} token
     * @param {*} data
     * @returns response
     */
    async downloadEditExcelCostList(token, data) {
        let config = authHeaders(token, {
            responseType: 'blob',
            headers: {}
        });
        return instance.post(`/downloads/editcostlist/excel/${data.id}`, data, config).then(response => {
            const blob = new Blob([response.data], {
                type: response.data.type
            });

            //レスポンスヘッダからファイル名を取得します
            const contentDisposition = response.headers["content-disposition"];
            const fileName = getFileName(contentDisposition)

            //ダウンロードします
            saveAs(blob, fileName);
        });
    },
    /**
     * エクセル作成
     * @param {*} token
     * @param {*} id
     * @returns response
     */
    async downloadExcelQuotation(token, id) {
        let config = authHeaders(token, {
            responseType: 'blob',
            headers: {}
        });
        return instance.get(`/downloads/quotation/excel/${id}`, config).then(response => {
            const blob = new Blob([response.data], {
                type: response.data.type
            });

            //レスポンスヘッダからファイル名を取得します
            const contentDisposition = response.headers["content-disposition"];
            const fileName = getFileName(contentDisposition)

            //ダウンロードします
            saveAs(blob, fileName);
        });
    },
    /**
     * エクセル作成(見積原稿作成画面)
     * @param {*} token
     * @param {*} data
     * @returns response
     */
    async downloadEditExcelQuotation(token, data) {
        let config = authHeaders(token, {
            responseType: 'blob',
            headers: {}
        });
        return instance.post(`/downloads/editquotation/excel/${data.id}`, data, config).then(response => {
            const blob = new Blob([response.data], {
                type: response.data.type
            });

            //レスポンスヘッダからファイル名を取得します
            const contentDisposition = response.headers["content-disposition"];
            const fileName = getFileName(contentDisposition)

            //ダウンロードします
            saveAs(blob, fileName);
        });
    },
    /**
     * PDF作成(見積原稿作成画面)
     * @param {*} token
     * @param {*} id
     * @param {*} data
     * @returns response
     */
    async downloadEditPdfQuotation(token, id, data) {
        let config = authHeaders(token, {
            responseType: 'blob',
            headers: {}
        });
        return instance.post(`/downloads/quotation/editpreview/${id}`, data, config).then(response => {
            const blob = new Blob([response.data], {
                type: response.data.type
            });

            //レスポンスヘッダからファイル名を取得します
            const contentDisposition = response.headers["content-disposition"];
            const fileName = getFileName(contentDisposition)

            //ダウンロードします
            saveAs(blob, fileName);
        });
    },
    /**
     * プレビュー
     * @param {*} token
     * @param {*} id
     * @param {*} data
     * @returns response
     */
    async previewQuotation(token, id, data) {
        let config = authHeaders(token, {
            responseType: 'blob',
            headers: {}
        });
        return instance.post(`/downloads/quotation/preview/${id}`, data, config).then(response => {
            const blob = new Blob([response.data], {
                type: response.data.type
            });

            //レスポンスヘッダからファイル名を取得します
            const contentDisposition = response.headers["content-disposition"];
            const fileName = getFileName(contentDisposition)

            //ダウンロードします
            saveAs(blob, fileName);
        });
    },
    /**
     * 見積書番号の重複チェック
     * @param {*} token
     * @param {*} data
     * @returns response
     */
    async isCreateDuplication(token, data) {
        return instance.post("/quotations/createduplication", data, authHeaders(token));
    },
    async isUpdateDuplication(token, data) {
        return instance.post("/quotations/updateduplication", data, authHeaders(token));
    },
    // メール送信
    async sendMail(token, data) {
        return instance.post("/send_mail/", data, authHeaders(token));
    },
    /**
     * 構成名称情報のリスト取得
     * @param {*} token
     * @returns response
     */
    async getStructures(token) {
        return instance.get("/structures/", authHeaders(token));
    },
    /**
     * カテゴリIDに紐づく構成名称情報のリスト取得
     * @param {*} token
     * @param {*} id
     * @returns response
     */
    async getFilterStructures(token, id) {
        return instance.get(`/structures/category/${id}`, authHeaders(token));
    },
    /**
     * 構成名称情報の取得
     * @param {*} token
     * @param {*} id
     * @returns response
     */
    async getStructure(token, id) {
        return instance.get(`/structures/${id}`, authHeaders(token));
    },
    /**
     * 構成名称情報の登録
     * @param {*} token
     * @param {*} data
     * @returns response
     */
    async createStructure(token, data) {
        return instance.post("/structures/", data, authHeaders(token));
    },
    /**
     * 構成名称情報の更新
     * @param {*} token
     * @param {*} id
     * @param {*} data
     * @returns response
     */
    async updateStructure(token, id, data) {
        return instance.put(`/structures/${id}`, data, authHeaders(token));
    },
    /**
     * 構成情報のリスト取得
     * @param {*} token
     * @returns response
     */
    async getConfigurations(token) {
        return instance.get("/configurations/", authHeaders(token));
    },
    /**
     * 構成情報の登録
     * @param {*} token
     * @param {*} data
     * @returns response
     */
    async createConfiguration(token, data) {
        return instance.post("/configurations/", data, authHeaders(token));
    },
    /**
     * 構成情報の更新
     * @param {*} token
     * @param {*} id
     * @param {*} data
     * @returns response
     */
    async updateConfiguration(token, id, data) {
        return instance.put(`/configurations/${id}`, data, authHeaders(token));
    },
    /**
     * 構成名称IDに紐づく構成情報のリスト取得
     * @param {*} token
     * @returns response
     */
    async getFilterConfigurations(token, structure_id) {
        return instance.get(`/configurations/${structure_id}`, authHeaders(token));
    },
    /**
     * アイテム価格情報のリスト取得
     * @param {*} token
     * @returns response
     */
    async getCostprices(token) {
        return instance.get("/costprices/", authHeaders(token));
    },
    /**
     * アイテム価格情報の登録
     * @param {*} token
     * @param {*} data
     * @returns response
     */
    async createCostprice(token, data) {
        return instance.post("/costprices/", data, authHeaders(token));
    },
    /**
     * アイテム価格情報の更新
     * @param {*} token
     * @param {*} id
     * @param {*} data
     * @returns response
     */
    async updateCostprice(token, id, data) {
        return instance.put(`/costprices/${id}`, data, authHeaders(token));
    },
    /**
     * アイテム価格情報の最新価格取得
     * @param {*} token
     * @param {*} id
     * @returns response
     */
    async getLatestCostprice(token, item_id) {
        return instance.get(`/costprices/latest/${item_id}`, authHeaders(token));
    },
    /**
     * アイテム情報の価格情報取得
     * @param {*} token
     * @param {*} item_id
     * @returns response
     */
    async getItemCostprices(token, item_id) {
        return instance.get(`/costprices/item/${item_id}`, authHeaders(token));
    },
    /**
     * 添付ファイルアップロード
     * @param {*} token
     * @param {*} id
     * @param {*} data
     * @returns response
     */
    async fileUpload(token, id, data) {
        return instance.post(`/uploads/uploadfile/${id}`, data, authHeaders(token));
    },
    /**
     * 添付ファイル削除
     * @param {*} token
     * @param {*} data
     * @returns response
     */
    async fileDelete(token, data) {
        return instance.post("/uploads/deletefile/", data, authHeaders(token));
    },
    /**
     * 添付ファイルダウンロード
     * @param {*} token
     * @param {*} file_path
     * @returns response
     */
    async fileDownload(token, file_path) {
        let config = authHeaders(token, {
            responseType: 'blob',
            headers: {},
        });
        const data = {
            name: file_path,
        };
        const response = await instance.post("/downloads/downloadfile/", data, config);
        const blob = new Blob([response.data], {
            type: response.data.type
        });

        //レスポンスヘッダからファイル名を取得します
        const contentDisposition = response.headers["content-disposition"];
        const fileName = getFileName(contentDisposition)

        //ダウンロードします
        saveAs(blob, fileName);

    },
    /**
     * 見積リスト取得
     * @param {*} token
     * @param {*} id
     * @returns response
     */
    async getLimitProject(token, id) {
        return instance.get(`/projects/${id}/quotations/`, authHeaders(token));
    },
    /**
     * 企業情報のリスト取得
     * @param {*} token
     * @returns response
     */
    async getCompanies(token) {
        return instance.get("/companies/", authHeaders(token));
    },
    /**
     * 企業情報の登録
     * @param {*} token
     * @param {*} data
     * @returns response
     */
    async createCompany(token, data) {
        return instance.post("/companies/", data, authHeaders(token));
    },
    /**
     * 企業情報の更新
     * @param {*} token
     * @param {*} id
     * @param {*} data
     * @returns response
     */
    async updateCompany(token, id, data) {
        return instance.put(`/companies/${id}`, data, authHeaders(token));
    },
    /**
     * 企業情報のロゴ取得
     * @param {*} token
     * @param {*} id
     * @returns response
     */
    async getLogo(token, id) {
        return instance.get(`/companies/logo/${id}`, authHeaders(token));
    },
};
